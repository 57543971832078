import React, { useContext, useState, useLazyRef, lazy, useMemo, useEffect } from 'react'
import {
    Switch,
    Route
} from "react-router-dom";
import { UserContext } from '../../context/provider/UserProvider';

import { LayoutMaster } from '../modules/layout/LayoutMaster';
import { PrivateRoute } from '../components/PrivateRoute';

import { Dashboard } from '../pages/Dashboard';
import { Ventas } from '../pages/Ventas/Ventas';
import { Productos } from '../pages/Productos/Productos';
import { Clientes } from '../pages/Clientes/Clientes';
import { Proveedores } from '../pages/Proveedores';
import { RegistroVenta } from '../pages/Ventas/RegistroVenta';
import { RegistroCompra } from '../pages/RegistroCompra';
import { Mapa } from '../pages/Mapa';
import { ListaVentas } from '../pages/ListaVentas';
import { ListaCompras } from '../pages/ListaCompras';
import { RegistroServicio } from '../pages/RegistrarServicio';
import { ListaServicio } from '../pages/ListarServicios';
import { Categorias } from '../pages/Productos/Categorias';
import { Marcas } from '../pages/Productos/Marcas';
import { Perfil } from '../pages/Perfil';
import { Caja } from '../pages/Ventas/Caja';
import { Banners } from '../pages/Banners';
// import { MetodosPago } from '../pages/MetodosPago';
import { MetodosPago } from '../pages/MetodosPago';
import { Banners2 } from '../pages/Banners2';
import { Pedidos } from '../pages/Pedidos';
import { FormasEntrega } from '../pages/FormasEntrega';

// import { Reporte1 } from '../pages/Reporte1';
import Reporte1 from '../pages/Reporte1';


import PageNotFound from '../pages/PageNotFound';


export function PrivateRoutes({ token }) {
    // const { stateUser } = useContext(UserContext);

    return (
        <>
            {
                token !== '' && localStorage.getItem('currentLocation') !== '/'
                && (
                    <LayoutMaster>
                        <Switch>
                            <PrivateRoute path='/dashboard' component={Dashboard}/>
                            <PrivateRoute path='/ventas' component={Ventas}/>
                            <PrivateRoute path='/reporte1' component={Reporte1}/>
                            <PrivateRoute path='/productos' component={Productos}/>
                            <PrivateRoute path='/clientes' component={Clientes}/>
                            <PrivateRoute path='/proveedores' component={Proveedores}/>
                            <PrivateRoute path='/registro-ventas' component={RegistroVenta}/>
                            <PrivateRoute path='/registro-compra' component={RegistroCompra}/>
                            <PrivateRoute path='/registro-servicio' component={RegistroServicio}/>
                            <PrivateRoute path='/categorias' component={Categorias}/>
                            <PrivateRoute path='/marcas' component={Marcas}/>
                            <PrivateRoute path='/mapa' component={Mapa}/>
                            <PrivateRoute path='/lista-ventas' component={ListaVentas}/>
                            <PrivateRoute path='/lista-compras' component={ListaCompras}/>
                            <PrivateRoute path='/lista-servicios' component={ListaServicio}/>
                            <PrivateRoute path='/perfil' component={Perfil}/>
                            <PrivateRoute path='/caja' component={Caja}/>
                            <PrivateRoute path='/banners' component={Banners}/>
                            <PrivateRoute path='/banners2' component={Banners2}/>
                            <PrivateRoute path='/metodos-pago' component={MetodosPago}/>
                            <PrivateRoute path='/formas-entrega' component={FormasEntrega}/>
                            <PrivateRoute path='/pedidos' component={Pedidos}/>
                            
                            <Route path="*" component={PageNotFound} />
                        </Switch>
                    </LayoutMaster>
                )
            }
        </>
    )
}