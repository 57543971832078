import React, { useEffect, useState, useRef,useMemo  } from 'react';
import { BotonNuevo } from '../../components/buttons/BotonNuevo';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import { SearchTable } from '../../components/utils/SearchTable';
import fotoDefault from '../../../assets/images/productoDefault.jpg';
import { useProductos } from '../../../hooks/useProductos';
import { useCategorias } from '../../../hooks/useCategorias';
import { Side } from '../../components/modal/Side';
import InputText from '../../components/InputText';
import SelectText from '../../components/SelectText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Button} from '@mui/material';
import InputEtiqueta from '../../components/InputEtiqueta';
import { soloDecimal } from '../../../utils/utils';
// import ImageCompressor from 'image-compressor'
import Switch, { SwitchProps } from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import iconoPublicarProducto from '../../../assets/images/iconPublicarProducto.fw.png'
import iconoNOPublicarProducto from '../../../assets/images/iconNOPublicarProducto.fw.png'
import iconoDestacar from '../../../assets/images/productoDestacado.fw.png'
import iconoNODescatar from '../../../assets/images/productoNODestacado.fw.png'
import { useMarcas } from '../../../hooks/useMarcas';



export const Productos = () => {
  const { 
    producto,
    listarProductos,
    guardarProductoConFoto,
    productos,
    editarValorProducto,
    obtenerProducto,
    isOpenModal,
    closeModal,
    setFotoFiles,
    eliminarProducto,
    isOpenModalEliminar,
    closeModalEliminar,
    productoEliminar
  } = useProductos()
  
  const { categorias } = useCategorias()
  const { marcas } = useMarcas()
  const [images, setImages] = useState([{ file: null, url: fotoDefault }]);
  const [ switchPublicar, setSwitchPublicar] = useState(true); 
  const [switchDestacar, setSwitchDestacar] = useState(true); 
  
  //para limpiar las imanges
  useEffect(() => {
    return () => {
      images.forEach((img) => {
        if (img.url) {
          URL.revokeObjectURL(img.url);
        }
      });
    };
  }, []); 
  
  //cargar las imagenes en un estado
  useEffect(() => {
    if (producto?.IdProducto) {
      const fotos = producto.Fotos ? producto.Fotos.split("|") : [];
      setImages([
        { file: null, url: fotos[0] || fotoDefault },
        { file: null, url: fotos[1] || fotoDefault },
        { file: null, url: fotos[2] || fotoDefault },
      ]);
    } else {
      setImages([
        { file: null, url: fotoDefault },
        { file: null, url: fotoDefault },
        { file: null, url: fotoDefault },
      ]);
    }
  }, [producto?.IdProducto]);

  //Cambiar de imagenes
  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const objectUrl = URL.createObjectURL(file); // Crea un URL único para la imagen cargada
      setImages((prev) => {
        // Crea una copia del estado actual y actualiza solo el índice correspondiente
        const updatedImages = [...prev];
        updatedImages[index] = { file, url: objectUrl }; 
        return updatedImages;
      });
    }
  };


  const handleSaveProduct = () => {
    const files = images.map((img) => img.file).filter(Boolean);
    const imageUrls = images.map((img) => img.url).join("|"); 
    setFotoFiles(files); 
    guardarProductoConFoto(files, imageUrls);
    console.log("Archivos que se enviarán a setFotoFiles:", files);
  }

  const opcionesCategoria = useMemo(() =>
    categorias.map((opcion) => ({
      id: opcion.IdCategoria,
      label: opcion.NombreCategoria,
    })),
    [categorias]
  )

  const opcionesMarca = useMemo(() =>
    marcas.map((opcion) => ({
      id: opcion.IdMarca,
      label: opcion.NombreMarca,
    })),
    [marcas]
  )

  const cambiarPublicado = () => {
    setSwitchPublicar(!switchPublicar); 
    editarValorProducto("Publicado", !switchPublicar? 1 : 0)
  };

  const cambiarDestacado = () => {
    setSwitchDestacar(!switchDestacar); 
    editarValorProducto("Destacado", !switchDestacar? 1 : 0)
  };

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 120,
    height: 35,
    padding: 10,
    margin: 1,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 1,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#000000',
            transform: 'translateX(75px)',
            '& .MuiSwitch-thumb:before': {
                backgroundSize: 'cover',
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aaafbe',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 30,
        height: 30,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aaafbe',
        borderRadius: 20 / 2,
    },
  }));
    
  const MaterialUISwitchDestacado = styled(Switch)(({ theme }) => ({
    width: 120,
    height: 35,
    padding: 10,
    margin:1,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 1,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
        color: '#000000',
        transform: 'translateX(75px)', 
        '& .MuiSwitch-thumb:before': {
            backgroundImage: `url(${iconoDestacar})`,
            backgroundSize: 'cover',
        },
        '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aaafbe',
        },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 30,
        height: 30,
        '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${iconoNODescatar})`,
        backgroundSize: 'cover',
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aaafbe',
        borderRadius: 20 / 2,
    },
  }));
  
  //Eliminar imagen
  const handleRemoveImage = (index) => {
    setImages((prev) => {
      const updatedImages = [...prev];
      updatedImages[index] = { file: null, url: fotoDefault };
      return updatedImages;
    });
  };

  return (
    <>
      <ContenedorParametros>
        <BotonNuevo onClick={() => obtenerProducto()} icono="fa fa-plus-circle"> Nuevo</BotonNuevo>
        <SearchTable tablaId="tablaProductos"></SearchTable>
      </ContenedorParametros>

      <div className="containerScroll relative contenedor-tabla ">
        <table id="tablaProductos" className="tableResponsiveProductos">

          <thead>

            <tr >
              <th className=''></th>
              <th className='w-[120px]'>Nombre</th>
              <th>Descripcion</th>
              <th>Categoria</th>
              <th>Marca</th>
              <th>Stock</th>
              <th>Precio Venta</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {productos.length > 0 ?
              productos.map((producto, i) => (
                <tr key={i + 1} className="text-center center center-items">
                  <td data-th="Foto" className=' pt-1 center-items  '>
                    <img
                      src={producto.Fotos?.split('|')[0]}
                      alt={producto.Nombre}
                      className="rounded w-20 h-20"
                    />
                  </td>
                  <td data-th="Nombre" className=''>{producto.Nombre}</td>
                  <td data-th="Descripcion" className='w-[30%]'>{producto.Descripcion}</td>
                  <td data-th="Categoria" className='w-auto'>{producto.NombreCategoria}</td>
                  <td data-th="Marca" className='w-auto'>{producto.NombreMarca}</td>
                  <td data-label='Stock ' data-th="Stock" className=''>{producto.Stock}</td>
                  <td data-label='Precio Venta ' data-th="PrecioVenta">S/ {producto.PrecioVenta}</td>
                  <td>
                    <BorderColorRoundedIcon
                      className="hover:text-green-300 text-green-500 rounded mr-3"
                      style={{ fontSize: "25px" }}
                      onClick={() => obtenerProducto(producto.IdProducto)}
                    />
                    <DeleteRoundedIcon
                      className="hover:text-red-300 text-red-500 rounded"
                      style={{ fontSize: "25px" }}
                      onClick={() => productoEliminar(producto.IdProducto)}
                    />
                  </td>
                </tr>
              ))
              : <tr ><td colSpan='7' className='text-center'>No tiene productos registrados o hubo un problema al cargar... Intente Nuevamente</td></tr>
            }
          </tbody>
        </table>
      </div>

      <Side 
        title={producto.IdProducto > 0 ? "Editar Producto" : "Registrar Producto"}
        isOpen={isOpenModal} closeModal={closeModal} 
        anchoModal={'80%'}
        action={() => {
          const files = images.map((img) => img.file).filter(Boolean);
          setFotoFiles(files);
          handleSaveProduct();
        }}
      >
        <div className=' lg:h-[500px] '>
          <div className="flex lg:flex-cosl items-center gap-4 justify-center">
            {/* Foto 1 */}
            <div className="relative w-[220px] h-[220px] mb-2 ">
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => handleImageChange(e, 0)}
                id="imageInput1"
              />

              <input
                type="file"
                accept="image/*"
                capture="environment"
                style={{ display: "none" }}
                onChange={(e) => handleImageChange(e, 0)}
                id="imageInputMobile1"
              />
              <img
                src={images[0]?.url}
                alt="Imagen 1"
                className="w-full h-full object-cover rounded-lg cursor-pointer"
                onClick={() => document.getElementById("imageInput1").click()}
              />
              <div
                className="absolute top-1 right-1 bg-gray-500 rounded-full px-2 text-white py-0 cursor-pointer hover:bg-red-600"
                onClick={() => handleRemoveImage(0)}
              >
                <span className="text-xl">×</span>
              </div>
              <div 
                className="lg:invisible absolute hover:bg-gray-800 bottom-2 left-2 text-white text-xs font-bold bg-black bg-opacity-50 px-2 py-1 rounded-full cursor-pointer"
                onClick={() => document.getElementById("imageInput1").click()}
              >
                Cambiar Imagen
              </div>
              <div
                className="invisible lg:visible absolute bottom-1 text-2xl right-1 bg-gray-700 bg-opacity-75 text-white px-4 py-1 rounded-xl cursor-pointer hover:bg-gray-900"
                onClick={() => document.getElementById("imageInputMobile1").click()}
              >
                <i className="fas fa-camera"></i>
              </div>
            </div>

            {/* Foto 2 */}
            <div className="relative w-[220px] h-[220px] mb-2">
              <input
                type="file"
                accept="image/*"
                // capture="environment"
                style={{ display: "none" }}
                onChange={(e) => handleImageChange(e, 1)}
                id="imageInput2"
              />
              <input
                type="file"
                accept="image/*"
                capture="environment"
                style={{ display: "none" }}
                onChange={(e) => handleImageChange(e, 1)}
                id="imageInputMobile2"
              />
              <img
                src={images[1]?.url}
                alt="Imagen 2"
                className="w-full h-full object-cover rounded-lg cursor-pointer"
                onClick={() => document.getElementById("imageInput2").click()}
              />
              <div
                className="absolute top-1 right-1 bg-gray-500 rounded-full px-2 text-white py-0 cursor-pointer hover:bg-red-600"
                onClick={() => handleRemoveImage(1)}
              >
                <span className="text-xl">×</span>
              </div>
              <div 
                className="lg:invisible absolute hover:bg-gray-800 bottom-2 left-2 text-white text-xs font-bold bg-black bg-opacity-50 px-2 py-1 rounded-full cursor-pointer"
                onClick={() => document.getElementById("imageInput2").click()}
              >
                Cambiar Imagen
              </div>
              <div
                className="invisible lg:visible absolute bottom-1 text-2xl right-1 bg-gray-700 bg-opacity-75 text-white px-4 py-1 rounded-xl cursor-pointer hover:bg-gray-900"
                onClick={() => document.getElementById("imageInputMobile2").click()}
              >
                <i className="fas fa-camera"></i> {/* Ícono de cámara */}
              </div>
            </div>

            {/* Foto 3 */}
            <div className="relative w-[220px] h-[220px] mb-2">
              <input
                type="file"
                accept="image/*"
                // capture="environment"
                style={{ display: "none" }}
                onChange={(e) => handleImageChange(e, 2)}
                id="imageInput3"
              />
              <input
                type="file"
                accept="image/*"
                capture="environment"
                style={{ display: "none" }}
                onChange={(e) => handleImageChange(e, 2)}
                id="imageInputMobile3"
              />
              <img
                src={images[2]?.url}
                alt="Imagen 3"
                className="w-full h-full object-cover rounded-lg cursor-pointer"
                onClick={() => document.getElementById("imageInput3").click()}
              />
              <div
                className="absolute top-1 right-1 bg-gray-500 rounded-full px-2 text-white py-0 cursor-pointer hover:bg-red-600"
                onClick={() => handleRemoveImage(2)}
              >
                <span className="text-xl">×</span>
              </div>
              <div 
                className="lg:invisible absolute hover:bg-gray-800 bottom-2 left-2 text-white text-xs font-bold bg-black bg-opacity-50 px-2 py-1 rounded-full cursor-pointer"
                onClick={() => document.getElementById("imageInput3").click()}
              >
                Cambiar Imagen
              </div>
              <div
                className="invisible lg:visible absolute bottom-1 text-2xl right-1 bg-gray-700 bg-opacity-75 text-white px-4 py-1 rounded-xl cursor-pointer hover:bg-gray-900"
                onClick={() => document.getElementById("imageInputMobile3").click()}
              >
                <i className="fas fa-camera"></i>
              </div>
            </div>
          </div>
          <InputText 

            classBox=''
            id='Nombre'
            label="Nombre"
            defaultValue={producto.Nombre}
            onChange={(e) => editarValorProducto("Nombre", e.target.value)}
          /> 
          <div className='flex lg:flex-col gap-2'>
            <InputEtiqueta 
              classBox='' 
              id='PrecioCompra' 
              label="Precio de Compra" 
              type="number" 
              defaultValue={producto.PrecioCompra} 
              onChange={(e) => editarValorProducto("PrecioCompra", e.target.value)} 
              onKeyPress={(e) => soloDecimal(e, e.target)}
            /> 
            <InputEtiqueta 
              classBox='' 
              id='PrecioVenta' 
              label="Precio de Venta" 
              type="number" 
              defaultValue={producto.PrecioVenta} 
              onChange={(e) => editarValorProducto("PrecioVenta", e.target.value)} 
              onKeyPress={(e) => soloDecimal(e, e.target)}
            /> 
                        <InputEtiqueta 
                            classBox='' 
                            id='PrecioPromocion' 
                            label="Precio de promoción" 
                            type="number" 
                            defaultValue={producto.PrecioPromocional} 
                            onChange={(e) => editarValorProducto("PrecioPromocional", e.target.value)} 
                            onKeyPress={(e) => soloDecimal(e, e.target)}
                        /> 
                    
                    
                    </div>
                    <div className='flex gap-2 lg:flex-col'> 

                        <SelectText
                            options={opcionesCategoria}
                            label="Categoria"
                            id="comboCategoria"
                            defaultValue={producto.IdCategoria ? opcionesCategoria.find((option) => option.id === producto.IdCategoria) : null}
                            onChange={(valor) => {
                                editarValorProducto("IdCategoria", valor.id);
                                editarValorProducto("NombreCategoria", valor.label)
                            }}
                        />

                        <SelectText
                            options={opcionesMarca}
                            label="Marca"
                            id="comboMarcas"
                            defaultValue={producto.IdMarca ? opcionesMarca.find((option) => option.id === producto.IdMarca) : null}
                            onChange={(valor) => {
                                editarValorProducto("IdMarca", valor.id);
                                editarValorProducto("NombreMarca", valor.label)
                            }}
              />
                                      <SelectText
                            options={opcionesMarca}
                            label="Unidad Medida"
                            id="comboMarcas"
                            defaultValue={producto.IdMarca ? opcionesMarca.find((option) => option.id === producto.IdMarca) : null}
                            onChange={(valor) => {
                                editarValorProducto("IdMarca", valor.id);
                                editarValorProducto("NombreMarca", valor.label)
                            }}
                        />
                    </div>
                    <InputText 
                        classBox=''
                        multilinea={3}
                        id='DescripcionProducto' 
                        label="Descripción" 
                        type="text" 
                        defaultValue={producto.Descripcion} 
                        onChange={(e) => editarValorProducto("Descripcion", e.target.value)}
                    /> 
                    <div className=' text-gray-800 '>

                        <FormControlLabel
                            control={
                                <MaterialUISwitchDestacado 
                                    style={{
                                        margin: 1,
                                        transition: 'transform 900ms cubic-bezier(0.4, 0, 0.2, 1)',
                                        transform: switchDestacar ? 'translateX(75px)' : 'translateX(5px)',
                                    }}                                    
                                    checked={switchDestacar}
                                    onChange={cambiarDestacado}
                                    // defaultChecked 
                                    />
                            }
                            label="Destacar"
                        />

                        <FormControlLabel
                            control={
                                <MaterialUISwitch 
                                style={{
                                    margin: 1,
                                    transition: 'transform 900ms cubic-bezier(0.4, 0, 0.2, 1)',
                                    transform: switchPublicar ? 'translateX(75px)' : 'translateX(5px)',
                                }}
                                checked={switchPublicar}
                                onChange={cambiarPublicado}
                                defaultChecked 
                                />
                            }
                            label="Publicar"
                        />
                    </div>

                 </div>




      </Side>

      <Side
        isOpen={isOpenModalEliminar} closeModal={closeModalEliminar} action={() => eliminarProducto(producto.IdProducto)}
        title= "Eliminar producto"
        spinner={{}}
        textButtons={{ confirm: 'Si', denied: 'No' }}
      >
        <div className=' text-center '>
          <ErrorOutlineIcon className='mb-4 center text-yellow-500 ' style={{ fontSize: "100px" }} />
          <div className="flex gap-8 items-center justify-center">
            <div className=" text-center">
              <label className="text-center text-xl text-gray-800">¿Está seguro de eliminar el Producto? </label>
              <hr></hr>
              <div className='text-center mt-5  content-center items-center'>
                <div className=''>
                    <label className="text-center  text-gray-800 font-bold text-2xl ">-- {producto.Nombre} --</label>
                    <img className='w-[250px]' src={producto.Fotos.split("|")[0]} alt={producto.Nombre}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Side>

        </>
    )

}