import { useState, useEffect,useContext } from 'react';
import { notify } from '../utils/utils';
import { useModal } from '../hooks/useModal';
import { AuthFetch } from '../services/api';
import { UserContext } from '../context/provider/UserProvider';

const categoriaDefault = {
  IdCategoria: 0,
  NombreCategoria: "",
  DescripcionCategoria: ""
}

const urlCategoria = process.env.REACT_APP_PROMETHEUS_API + '/categorias' 

export const useCategorias = () => {
  const { stateUser } = useContext(UserContext)
  const [ categorias, setCategorias] = useState([]);
  const [ categoria, setCategoria ] = useState(categoriaDefault);
  const [ isOpenModal, openModal, closeModal ] = useModal();
  const [ isOpenModalEliminar, openModalEliminar, closeModalEliminar ] = useModal();

  useEffect(() => {
      listarCategorias();
  }, []);

  const listarCategorias = async () => {
    const response = await AuthFetch({url: urlCategoria + '?' + new URLSearchParams({base_datos:stateUser?.baseDatos})});
    if (response.isValid) {
      setCategorias(response.content);
    } else {
      notify(response.error, 'error');
    }
  }

  const obtenerCategoria = async (idCategoria) => {
    if (idCategoria) {
      const response = await AuthFetch({url: `${urlCategoria}/${idCategoria}?` + new URLSearchParams({base_datos:stateUser?.baseDatos})});
        if (response.isValid) {
          setCategoria(response.content);
        } else {
          notify(response.error, 'error');
        }
    } else {
      setCategoria(categoriaDefault);
    }
    openModal();
  }

  const categoriaEliminar = async (idCategoria) => {
    if (idCategoria) {
      const response = await AuthFetch({url: `${urlCategoria}/${idCategoria}?` + new URLSearchParams({base_datos:stateUser?.baseDatos})});
      if (response.isValid) {
        setCategoria(response.content);
      } else {
        notify(response.error, 'error');
      }
    } else {
      setCategoria(categoriaDefault);
    }
    openModalEliminar();
  }

  const eliminarCategoria = async (idCategoria) => {
    const response = await AuthFetch({url: `${urlCategoria}/${idCategoria}?`+ new URLSearchParams({base_datos : stateUser?.baseDatos}),
      method: 'DELETE'
    })
    if (response.isValid) {
      listarCategorias()
      notify(response.content, 'success')
    } else { 
      notify(response.error, 'error')
    }
    closeModalEliminar()
  }

  const guardarEditarCategoria = async () => {
    const esGuardar = categoria.IdCategoria <= 0;
    const response = await AuthFetch({
      url: `${urlCategoria}${esGuardar ? '' : '/' + categoria.IdCategoria}?` + new URLSearchParams({
        base_datos: stateUser?.baseDatos
      }),
      method: esGuardar ? 'POST' : 'PUT',
      body: JSON.stringify({
        NombreCategoria: categoria.NombreCategoria,
        DescripcionCategoria: categoria.DescripcionCategoria,
      })
    });

    if (response.isValid) {
      notify(response.content, 'success');
      setCategoria(categoriaDefault);
      await listarCategorias();
      closeModal()
    } else { 
      notify(response.error,'error');
    }
  }
    
  const editarValorCategoria = (key, value) => {
    setCategoria(categoria => {
      return {
        ...categoria,
        [key]: value
      }
    });
  }

  return {
    categoria,
    listarCategorias,
    categorias,
    editarValorCategoria,
    obtenerCategoria,
    isOpenModal,
    closeModal,
    eliminarCategoria,
    isOpenModalEliminar,
    closeModalEliminar,
    categoriaEliminar,
    guardarEditarCategoria
  }
}