import { useState, useEffect,useContext } from 'react';
import { notify } from '../utils/utils';
import { useModal } from '../hooks/useModal';
import { AuthFetch } from '../services/api';
import { UserContext } from '../context/provider/UserProvider';

const marcaDefault = {
    IdMarca: 0,
    NombreMarca: "",
    DescripcionMarca: ""
}
const urlMarca = process.env.REACT_APP_PROMETHEUS_API + '/marcas' 
export const useMarcas = () => {
  const { stateUser } = useContext(UserContext)
  const [ marcas, setMarcas] = useState([]);
  const [ marca, setMarca ] = useState(marcaDefault);
  const [ isOpenModal, openModal, closeModal ] = useModal();
  const [ isOpenModalEliminar, openModalEliminar, closeModalEliminar ] = useModal();

  useEffect(() => {
      listarMarcas();
  }, []);

  const listarMarcas = async () => {
    const response = await AuthFetch({url: urlMarca + '?'+ new URLSearchParams({base_datos:stateUser?.baseDatos})});
    if (response.isValid) {
      setMarcas(response.content);
    } else {
      notify(response.content, 'error');
    }
  }

  const obtenerMarca = async (idMarca) => {
    if (idMarca) {
      const response = await AuthFetch({url:`${urlMarca}/${idMarca}?` + new URLSearchParams({base_datos : stateUser?.baseDatos})});
      if (response.isValid) {
        setMarca(response.content);
      } else {
        notify(response.error, 'error');
      }
    } else {
      setMarca(marcaDefault);
    }
    openModal();
  }

  const marcaEliminar = async (idMarca) => {
    if (idMarca) {
      const response = await AuthFetch({
        url: `${urlMarca}/${idMarca}?` + new URLSearchParams({
          base_datos: stateUser?.baseDatos
        })
      });

      if (response.isValid) {
        setMarca(response.content);
      } else {
        notify(response.error, 'error');
      }
    } else {
      setMarca(marcaDefault);
    }
    openModalEliminar();
  }

    
  const eliminarMarca = async (idMarca) => {
    const response = await AuthFetch({
      url: `${urlMarca}/${idMarca}?` + new URLSearchParams({
        base_datos: stateUser?.baseDatos
      }),
      method: 'DELETE'
    })
    
    if (response.isValid) {
      notify(response.content, 'success')
      listarMarcas()
    } else { 
      notify(response.error, 'error')
    }
    closeModalEliminar()
  }

    
  const guardarEditarMarca = async () => { 
    const esGuardar = marca.IdMarca <= 0;
    const response = await AuthFetch({     
      url: `${urlMarca}${esGuardar ? '' : '/' + marca.IdMarca}?` + new URLSearchParams({
        base_datos: stateUser?.baseDatos
      }),
      method: esGuardar ? 'POST' : 'PUT',
      body: JSON.stringify({
        NombreMarca: marca.NombreMarca,
        DescripcionMarca: marca.DescripcionMarca,
      })
    });
  
    if (response.isValid) {
      notify(response.content,'success');
      setMarca(marcaDefault);
      await listarMarcas();
      closeModal()
    } else {
      notify(response.error, 'error');
    }
  }

  const editarValorMarca = (key, value) => {
    setMarca(marca => {
      return {
        ...marca,
        [key]: value
      }
    });
  }

  return {
    marca,
    listarMarcas,
    marcas,
    editarValorMarca,
    obtenerMarca,
    isOpenModal,
    closeModal,
    eliminarMarca,
    isOpenModalEliminar,
    closeModalEliminar,
    marcaEliminar,
    guardarEditarMarca
  }
}