
import { notify } from "../utils/utils";

export const AuthFetch = async ({
    url,
    method,
    headers,
    body,
    typeResponse
}) => {
    // const { stateUser, signOut } = useContext(UserContext)
    const decifrado = atob(localStorage.getItem('pm-session') || '')
    const datosSession = decifrado == '' ? {} : JSON.parse(decifrado);
    const token = 'Bearer' + ' ' + datosSession?.token;
    
    const headersDefault = headers || {
        'Content-Type': 'application/json',
    };

    const response = await fetch(url, {
        method: method || 'GET',
        headers: {
            ...headersDefault,
            'Authorization': token
        },
        body: body || null
    });

    if ([401].includes(Number(response.status))) {
        localStorage.clear();
        window.location.reload(false);
    }

    return await response[typeResponse || 'json']()
}




export const Fetch = async ({
    url,
    method,
    headers,
    body,
    typeResponse
}) => {

    const headersDefault = headers || {
        'Content-Type': 'application/json',
    };

    const response = await fetch(url, {
        method: method || 'GET',
        headers: {
            ...headersDefault,
        },
        body: body || null
    });

  if ([401].includes(Number(response.status))) {
      localStorage.clear();
      window.location.reload(false);
      notify("Sesión caducada","info")
      
  }
      if ([403].includes(Number(response.status))) {
        localStorage.clear();
        window.location.reload(false);
        notify("Sesión caducada","info")
    }
    return await response[typeResponse || 'json']()
}


export const fetchFormData = async ({
    url,
    method = "POST",
    body,
    typeResponse = "text", // Por defecto, esperamos texto
}) => {
    const response = await fetch(url, {
        method,
        headers: {
            accept: "application/json", // Encabezado mínimo necesario
        },
        body, // Enviar el FormData
        redirect: "follow", // Manejar redirecciones automáticamente
    });

    if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.statusText}`);
    }

    // Retornar según el tipo de respuesta esperado
    return await response[typeResponse]();
};

