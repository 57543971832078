import React, { useState, useEffect } from "react";
import { Spinner } from '../utils/Spinner';
import { useSpinner } from "../../../hooks/useSpinner";
import Draggable from "react-draggable";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export const Side = ({
    isOpen, 
    closeModal, 
    action, 
    width, 
    children, 
    title, 
    anchoModal,
    cerrarAlGuardar = false,
    textButtons = { confirm: 'Guardar', denied: 'Cancelar' }, 
    validButton = { confirm: true , denied: true}
}) => {
    const [spinner, mostrarSpinner, ocultarSpinner] = useSpinner(); 
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      if (isOpen) {
        setTimeout(() => setIsVisible(true), 10);
      } else {
        const timeout = setTimeout(() => setIsVisible(false), 200);
        return () => clearTimeout(timeout);
      }
    }, [isOpen]);

    return (
        isVisible && (
            <div
              className={`z-50 fixed right-0 top-[100px] bottom-0 bg-gray-200 transition-all duration-300 ease-in-out transform 
              ${isOpen ? 'translate-x-0 opacity-100 scale-100' : 'translate-x-full opacity-0 scale-95'}
                w-[${anchoModal || '40%'}]  lg:w-full text-white shadow-lg rounded-tl-lg lg:h-[86%]`}
            >
                {/* Header */}
                <div className="h-[60px] flex items-center bg-gradient-to-r from-orange-500 to-red-500 text-white p-3 rounded-tl-lg">
                    <IconButton
                        color="inherit"
                        onClick={closeModal}
                        className="hover:scale-110 transition-transform duration-200"
                    >
                        <i className='fa fa-angle-double-right'></i>
                    </IconButton>
                    <h3 className="text-xl font-semibold ml-4">{title}</h3>
                </div>

                {/* Content */}
                <div className="h-[calc(100%-60px)] shadow-inner relative flex flex-col w-full bg-[#fcfcfc] outline-none focus:outline-none ">
                    <div className="relative p-4 flex-auto overflow-y-scroll containerScroll">
                        {children}
                    </div>

                    {/* Footer */}
                    <div className="border-t border-gray-300 bg-gray-200 p-2 gap-3 flex items-center justify-center lg:flex-col">
                        {validButton.confirm && (
                            <Button
                                variant="contained"
                                color="success"
                                onClick={async () => {
                                    mostrarSpinner();
                                    await action();
                                    ocultarSpinner();
                                    cerrarAlGuardar && closeModal();
                                }}
                                className="lg:w-full h-[45px]"
                            >
                                {spinner ? <Spinner spinner={spinner.toString()}/> : textButtons.confirm}
                            </Button>
                        )}
                        {validButton.denied && (
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={closeModal}
                                className="lg:w-full h-[45px]"
                            >
                                {textButtons.denied}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        )
    );
};