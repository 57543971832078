import React, { useState, useRef, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { UserContext } from '../../context/provider/UserProvider';
import { notify } from '../../utils/utils';
import logo from '../../assets/images/logoPrometheusInicio.fw.png';
import { BotonNeon } from '../components/buttons/BotonNeon';
import { Loading } from '../components/Loading';
import { TextField, IconButton, InputAdornment, Button, Box } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';


export const iniciarSesion = async (user, password, signIn) => {
    
  const response = await fetch(process.env.REACT_APP_PROMETHEUS_API + '/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      Usuario: user,
      Clave: password
    })
  }).then(res => res.json());
  
  if (response.isValid) {
    console.log("RESPONSE-> ", response)
    const timeExpire = new Date(new Date().getTime() + response.content.tiempoExpiraMiliSegundos * 1000);
    const baseDeDatos = response.content?.accesos[0]?.baseDatos || ''
    signIn(
      {
        ...response.content,
        tokenExpire: timeExpire,
        baseDatos :baseDeDatos,
      }
    );
    return true
  }
  return false
    // return true
}





const useLogin = () => {
    const inputUsuario = useRef();
    const history = useHistory();
    const { stateUser, signIn } = useContext(UserContext);
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [mostrarLoading, setMostrarLoading] = useState(false);

    const ingresar = async (e) => {
        setMostrarLoading(true)
        e.preventDefault();
        const ingreso = await iniciarSesion(user, password, signIn);
        if (ingreso) {
            history.push('/dashboard')      

            // AREA DE NOTIFICACIONES PUSH
            if(Notification.permission === "default"){
                await Notification.requestPermission()
            }

            if(Notification.permission === "blocked"){
                alert("Notificaciones bloqueadas")
            }

            if(Notification.permission !== "granted"){
                return;
            }
            ///FIN AREA DE NOTICIONES 
        } else {
            notify('Usuario o contraseña incorrectos', 'error')
        }
        setMostrarLoading(false)
        
    }
    return {
        user,
        setUser,
        password,
        setPassword,
        ingresar,
        inputUsuario,
        stateUser,
        mostrarLoading,
    };
};

export const Login = () => {
    const login = useLogin();
    const [mostrarContrasena, setMostrarContrasena] = useState(false);

    return (
        <div className="flex flex-col h-screen bg-[#101b27]">
            {login.mostrarLoading && <Loading />}
            <div className="grow container flex justify-center items-center -mt-10 lg:mt-0">
                <div className="w-[500px] lg:w-[89%] flex flex-col items-center gap-y-2 ">
                    <img src={logo} alt="" className="w-[350px] mb-4 lg:mb-5 lg:w-[80%]" />
                    <Box
                        sx={{
                            width: '100%',
                            // height: '90%',
                            bgcolor: 'rgba(33, 43, 54, 0.4)',
                            padding: 4,
                            borderRadius: 2,
                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.7)', 
                        }}
                    >
                        <form onSubmit={login.ingresar}>
                <h2
                  className=' border-b border-gray-700 text-white text-center text-3xl mb-10'>
                                Iniciar sesión
                            </h2>
                            <Box sx={{ mb: 4 }}>
                                <TextField
                                    fullWidth
                                    inputRef={login.inputUsuario}
                                    onChange={(e) => login.setUser(e.target.value)}
                                    value={login.user}
                                    label="Usuario"
                                    variant="outlined"
                                    InputLabelProps={{
                                        sx: { color: 'white', fontSize:18 },
                                    }}
                                    sx={{
                                        input: { color: 'white' },
                                        '& .MuiOutlinedInput-root': {
                                            '&:hover fieldset': { borderColor: '#f6462f' },
                                            '&.Mui-focused fieldset': { borderColor: '#f6462f' },
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': { color: 'white' },
                                    }}
                                />
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <TextField
                                    fullWidth
                                    onChange={(e) => login.setPassword(e.target.value)}
                                    value={login.password}
                                    type={mostrarContrasena ? 'text' : 'password'}
                                    label="Contraseña"
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setMostrarContrasena(!mostrarContrasena)}
                                                    edge="end"
                                                    sx={{ color: 'white', fontSize:18  }}
                                                >
                                                    {mostrarContrasena ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        sx: { color: 'white' },
                                    }}
                                    sx={{
                                        input: { color: 'white' },
                                        '& .MuiOutlinedInput-root': {
                                            '&:hover fieldset': { borderColor: '#f6462f' },
                                            '&.Mui-focused fieldset': { borderColor: '#f6462f' },
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': { color: 'white' },
                                    }}
                                />
                            </Box>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    bgcolor: '#f6462f',
                                    height: '56px', // Botón más alto
                                    fontSize: '1.2rem',
                                    fontWeight: 'bold',
                                    '&:hover': { bgcolor: '#f6682f' },
                                    mb: 5,
                                }}
                            >
                                Ingresar
                            </Button>
                            <div className="text-center mb-3">
                                <BotonNeon
                                    estilo="btn-7"
                                    type="button"
                                    onClick={() => console.log('Contactarme')}
                                >
                                    Contáctanos
                                </BotonNeon>
                            </div>
                        </form>
                    </Box>
                </div>
            </div>
        </div>
    );
};
