import React, { useEffect, useState, useRef } from 'react';
import { BotonNuevo } from '../../components/buttons/BotonNuevo';
import { ContenedorParametros } from '../../components/utils/ContenedorParametros';
import { SearchTable } from '../../components/utils/SearchTable';
import { useMarcas } from '../../../hooks/useMarcas';
import { Side } from '../../components/modal/Side';
import InputText from '../../components/InputText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const Marcas = () => {

  const { 
    marca,
    marcas,
    editarValorMarca,
    obtenerMarca,
    isOpenModal,
    closeModal,
    eliminarMarca,
    isOpenModalEliminar,
    closeModalEliminar,
    marcaEliminar,
    guardarEditarMarca
  } = useMarcas()

  const idTablaMarcas = "tablaMarcas"
   
  return (
    <>
      <ContenedorParametros>
        <BotonNuevo onClick={() => obtenerMarca()} icono="fa fa-plus-circle">Nuevo</BotonNuevo>
        <SearchTable tablaId={idTablaMarcas}></SearchTable>
      </ContenedorParametros>
      <div className="containerScroll relative contenedor-tabla ">
        <table id={idTablaMarcas} className="tableResponsiveProductos" >
          <thead className=''>
            <tr >
              <th>N</th>
              <th>Nombre</th>
              <th>Descripción</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {marcas.map((marca, i) => (
              <tr key={marca.IdMarca} className="text-center center center-items">
                <td data-th="">{++i}</td>
                <td data-th="Nombre">{marca.NombreMarca}</td>
                <td data-th="Descripcion">{marca.DescripcionMarca}</td>
                <td data-th="Opciones">
                  <button onClick={() => obtenerMarca(marca.IdMarca)} className="hover:text-green-300 text-green-500 text-xl px-[5px] py-1 rounded">
                    <i className="fas fa-edit"></i>
                  </button>
                  <button onClick={() => marcaEliminar(marca.IdMarca)} className="hover:text-red-300 text-red-500 text-xl px-[5px] py-1 rounded ml-3">
                    <i className="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Side 
        isOpen={isOpenModal} closeModal={closeModal} 
        action={() => guardarEditarMarca()}
        title="Registrar Marca"
      >
        <div className='h-[300px]  lg:h-[500px] '>
          <InputText 
            classBox=''
            id='nombreMarca'
            label="Nombre de Marca"
            type="text"
            defaultValue={marca.NombreMarca}
            onChange={(e) => editarValorMarca("NombreMarca", e.target.value)}
            className='caret-gray'
          />
          <InputText classBox='' multilinea={4} id='descripcionMarca' label="Descripción" type="text" defaultValue={marca.DescripcionMarca} onChange={(e) => editarValorMarca("DescripcionMarca", e.target.value)} /> 
        </div>
      </Side>

      <Side 
        isOpen={isOpenModalEliminar} closeModal={closeModalEliminar} action={() => eliminarMarca(marca.IdMarca)}
        title= "Eliminar Marca"
        spinner={{}}
        textButtons={{ confirm: 'Si', denied: 'No' }}
      >
        <div className=' text-center '>
          <ErrorOutlineIcon className='mb-4 center text-yellow-500 ' style={{ fontSize: "100px" }} />
          <div className="flex gap-8 items-center justify-center">
            <div className=" text-center">
              <label className="text-center text-xl text-gray-800">¿Está seguro de eliminar la Marca? </label>
              <hr></hr>
              <div className='text-center mt-5  content-center items-center'>
                <div className=''>
                  <label className="text-center  text-gray-800 font-bold text-2xl ">-- {marca.NombreMarca} --</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Side>
    </>
  )
}